/** Parent only routes */
import MyChild from "@/views/parents/MyChild.vue";
import MyChildren from "@/views/parents/MyChildren.vue";
import EditMyChild from "@/views/parents/EditMyChild.vue";
import ChildOutcomesIndex from "@/views/parents/ChildOutcomesIndex.vue";
import ChildOutcomePage from "@/views/parents/ChildOutcomePage.vue";

export const parentRoutes = [
  {
    path: "/dashboard/children",
    name: "My_Children",
    component: MyChildren,
    meta: {
      name: "My Children",
      breadcrumbs: ["Dashboard", "My_Children"],
    },
  },
  {
    path: "/dashboard/children/:member_id",
    name: "View_Child",
    component: MyChild,
    meta: {
      name: "View Child",
      breadcrumbs: ["Dashboard", "My_Children", "View_Child"],
    },
  },
  {
    path: "/dashboard/children/:member_id/edit",
    name: "Edit_Child",
    component: EditMyChild,
    meta: {
      name: "Edit Child",
      breadcrumbs: ["Dashboard", "My_Children", "View_Child", "Edit_Child"],
    },
  },
  {
    path: "/dashboard/children/outcomes",
    name: "Child_Outcomes",
    component: ChildOutcomesIndex,
    meta: {
      name: "Child Outcomes",
      breadcrumbs: ["Dashboard", "Child_Outcomes"],
    },
  },
  {
    path: "/dashboard/children/outcomes/:member_id",
    name: "Child_Outcome",
    component: ChildOutcomePage,
    meta: {
      name: "Child Outcome",
      breadcrumbs: ["Dashboard", "Child_Outcomes", "Child_Outcome"],
    },
  },
];
