import { ref } from "vue";
import { RouteLocationNormalizedLoaded } from "vue-router";

import router from "@/router";

function useFilters(route: RouteLocationNormalizedLoaded) {
  const filters = ref({} as { [key: string]: any });

  /** Get Filters from Route Query */
  function getFiltersFromQuery() {
    if (route.query) {
      Object.entries(route.query).forEach(param => {
        filters.value[param[0]] = param[1];
      });
    }
  }

  /** Sort By */
  async function sortBy(col: string, dir: string) {
    filters.value.page = 1;
    filters.value.sort = col;
    filters.value.dir = dir;

    filter();
  }

  /** Set Filter */
  async function setFilter(ref: string, value: string) {
    filters.value.page = 1;
    filters.value[ref] = value;

    filter();
  }

  /** Set Filter (No Refresh) */
  async function setFilterNoRefresh(ref: string, value: string) {
    filters.value[ref] = value;
  }

  /** Reset Filters */
  function resetFilters() {
    filters.value = {};

    filter();
  }

  /** Reset Filters (No Refresh) */
  function resetFiltersNoRefresh() {
    filters.value = {};
    console.log("resetFiltersNoRefresh", filters.value);
  }

  /** Filter the route */
  async function filter() {
    if (filters.value.search !== route.query.search) {
      await setFilterNoRefresh("page", "1");
    }

    if (filters.value.type !== route.query.type) {
      await setFilterNoRefresh("page", "1");
    }

    return await router
      .replace({
        name: router.currentRoute.value.name as string,
        query: Object.fromEntries(Object.entries(filters.value).filter(([, v]) => v != "")),
      })
      .then(() => {
        location.reload();
      });
  }

  return {
    filters,
    setFilter,
    setFilterNoRefresh,
    resetFilters,
    resetFiltersNoRefresh,
    getFiltersFromQuery,
    filter,
    sortBy,
  };
}

export { useFilters };
