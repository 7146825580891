export default {
  "new-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new event"])},
  "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
  "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Community"])},
  "email-marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Marketing"])},
  "email-preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Preferences"])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "my-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Profile"])},
  "account-security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Security"])},
  "add-a-new-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new User"])},
  "user-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Profile"])},
  "user-groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Groups"])},
  "user-group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Group"])},
  "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses"])},
  "course-prerequisites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Prerequisites"])},
  "course-templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Templates"])},
  "add-a-new-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new Template"])},
  "edit-course-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Course Template"])},
  "training-events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training Events"])},
  "add-a-new-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new Event"])},
  "training-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training Event"])},
  "user-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Order"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resources"])},
  "upload-Resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Resource"])},
  "resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource"])},
  "edit-Resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Resource"])},
  "discounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discounts"])},
  "add-a-new-Discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new Discount"])},
  "add-brick-club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Brick Club"])},
  "edit-Discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Discount"])},
  "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
  "certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificates"])},
  "brick-clubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brick Clubs"])},
  "view-club-participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View club member"])},
  "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["summary"])},
  "course-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["course description"])},
  "registered-attendees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registered attendees"])},
  "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["billing"])},
  "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["individual"])},
  "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["course"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all"])},
  "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancelled"])},
  "scheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["scheduled"])},
  "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["draft"])},
  "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["completed"])},
  "confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirmed"])},
  "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["published"])},
  "no-registrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no registrations"])},
  "minimum-not-met": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minimum not met"])},
  "in review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in review"])},
  "unpublished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unpublished"])},
  "event-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["event name"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["actions"])},
  "training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["training"])},
  "trainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trainer"])},
  "course-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["course template"])},
  "course-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["course level"])},
  "course-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["course type"])},
  "delivery-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delivery method"])},
  "delivery-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delivery date"])},
  "available-countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["available countries"])},
  "date-and-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date and time"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["location"])},
  "training-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["training link"])},
  "event-owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["event owner"])},
  "presenters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["presenters"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["price"])},
  "payment-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["payment link"])},
  "payment-gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Gateway"])},
  "attendance-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["attendace limit"])},
  "maximum-attendees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maximum number of participants"])},
  "minimum-attendees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minimum number of participants"])},
  "waiting-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["waiting list"])},
  "go-live": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date to publish this event"])},
  "registration-cut-off-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registration cut off date"])},
  "start-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["start time"])},
  "end-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["end time"])},
  "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["region"])},
  "organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["organisation"])},
  "payment-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["payment method"])},
  "payment-provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["payment platform"])},
  "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paid"])},
  "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["complete"])},
  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["view"])},
  "event-submission-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for submitting an event. Play Included will review the event and publish to the website. For events requiring licence fee payment: Play Included will now issue you with an invoice for payment. The course will be published to the website on the ‘go live’ date once payment has been received. For events which do not require licence fee payment: Play Included will approve the course and publish to the website on the ‘go live’ date set."])},
  "this-event-is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This event is"])},
  "course-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["course feedback"])},
  "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rating"])},
  "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["feedback"])},
  "welcome-back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome back!"])},
  "your-training-events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Training Events"])},
  "submit-event-for-approval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit Event for Approval"])},
  "edit-training-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Training Event"])},
  "save-training-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Training Event"])},
  "confirm-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Event"])},
  "publish-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish Event Now"])},
  "unpublish-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unpublish Event"])},
  "cancel-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Event"])},
  "change-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Price"])},
  "add-a-presenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a presenter"])},
  "add-another-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add another date"])},
  "select-available-countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Available Countries"])},
  "make-training-event-go-live": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make Training Event for Live"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete"])},
  "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remove"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancel"])},
  "save-changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save changes"])},
  "delete-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete account"])},
  "change-your-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["change your password"])},
  "add-session-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Session Note"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "add-existing-participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add existing participant"])},
  "save-participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["save participant"])},
  "edit-brick-club-participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit brick club participant"])},
  "delete-brick-club-participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete brick club participant"])},
  "add-new-participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add new participant"])},
  "first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["first name"])},
  "last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last name"])},
  "email-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email address"])},
  "email-address-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changing this will require you to login again"])},
  "your-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["your roles"])},
  "your-roles-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter each job on a new line"])},
  "bio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bio"])},
  "facilitator-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["facilitator settings"])},
  "trainer-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trainer settings"])},
  "register-of-practitioners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register of Practitioners"])},
  "facilitator-public-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This profile is currently not visible on the website. If you would like to be listed on the website, toggle this setting on."])},
  "public-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When toggled on, your profile will be visible on the "])},
  "public-message-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" which includes your; Organisation, Country, Roles and the age range of members you work with along with a link to your Brick clubs."])},
  "works-with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work with"])},
  "trainer-public-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This profile is currently not visible on the website. If you would like it to be listed on the website, toggle this setting on"])},
  "language-and-region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["language and region"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["city"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["language"])},
  "timezone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["timezone"])},
  "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["code"])},
  "promotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["promotion"])},
  "starts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["starts"])},
  "expires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["expires"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["country"])},
  "limit-per-customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["limit per customer"])},
  "limit-total-uses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["limit total uses"])},
  "starts-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["start at"])},
  "expires-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["expires at"])},
  "specific-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["specific user"])},
  "specific-organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["specific organisation"])},
  "apply-to-courses-tagged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["apply to courses tagged"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date"])},
  "venue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["venue"])},
  "setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["setting"])},
  "course-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["course name"])},
  "course-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["course date"])},
  "first-club-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["first club date"])},
  "last-club-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last club date"])},
  "participants-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number of participants"])},
  "places-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["places available"])},
  "session-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["session notes"])},
  "age-range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["age range"])},
  "session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["session"])},
  "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["note"])},
  "initials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["initials"])},
  "background-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["background notes"])},
  "brick-club-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["brick club notes"])},
  "created-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])},
  "course-trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigger at the end of"])},
  "last-modified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last modified"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["status"])},
  "facilitator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilitator"])},
  "date-completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Completed"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
  "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["created"])},
  "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["updated"])},
  "training-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training Feedback"])},
  "submit-for-review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["submit for review"])},
  "contact-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact email"])},
  "contact-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact us"])},
  "contact-trainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact trainer"])},
  "save-brick-club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["save brick club"])},
  "edit-brick-club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit brick club"])},
  "mobile-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mobile number"])},
  "for-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For"])},
  "survey-recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["survey recipient/s"])},
  "expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["expiration"])},
  "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["level"])},
  "payment-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["payment type"])},
  "auto-renew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["auto renew"])},
  "auto-renew-explainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you wish your subscription to auto renew please toggle the auto renew on which will mean your subscription will continue without disruption*. If you would prefer to handle your subscription renewal manually, please toggle to off."])},
  "auto-renew-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Providing your card has not expired."])},
  "child-outcomes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["child outcomes"])},
  "club-trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigger every weeks"])},
  "show-parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["show parent"])},
  "discount-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["discount code"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["submit"])},
  "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["files"])},
  "upload-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["upload files"])},
  "brick-club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brick Club"])},
  "member-session-average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member session average"])},
  "move-this-child-to-new-club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move this child to new Brick CLub"])},
  "organisations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["organisations"])},
  "first-name-initial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["first name initial"])},
  "family-name-initial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family Name Initial"])},
  "middle-name-initial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Middle Name Initial(s) (optional)"])},
  "parent-carer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["parent/carer"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])},
  "edit-club-participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Club Participant"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["welcome"])},
  "primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primary"])},
  "additional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["additional"])},
  "medication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["medication"])},
  "thank-you-parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank You"])},
  "brick-club-members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brick Club Members"])},
  "view-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Member"])},
  "Edit-Member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Member"])},
  "scheduled-events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled Events"])},
  "booked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["booked"])},
  "order-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order No."])},
  "placed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["placed"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["type"])},
  "website-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website Data"])},
  "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders"])},
  "my-children": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Children"])},
  "my-child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Child"])},
  "edit-child-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit Child Details"])},
  "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["added"])},
  "edit-child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Child"])},
  "capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["capacity"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["amount"])},
  "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quantity"])},
  "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["user"])},
  "invoice-issued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Issued"])},
  "add-dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add dates"])},
  "add-multiple-dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add multiple dates"])},
  "capacity-of-club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacity of Club"])},
  "add-new-club-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new club member"])},
  "add-another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add another"])},
  "save-club-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["save club member"])},
  "edit club member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit club member"])},
  "show-members-parent-carer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show member's parent/carer"])},
  "brick-club-note-for-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brick Club note for Member"])},
  "visible-to-member-parent-carer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visible to member’s parent/carer"])},
  "my-brick-clubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Brick Clubs"])},
  "manage-brick-club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Brick Club"])},
  "add-note-for-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add note for member"])},
  "visible-to-members-parent-carer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visible to Member's Parent/Carer"])},
  "facilitator-session-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilitator session checklist and notes"])},
  "please-select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select"])},
  "parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["parent"])},
  "participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["participant"])},
  "save-survey-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["save-survey-details"])},
  "no-responses-to-this-survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No responses to this Survey"])},
  "view-sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Sessions"])},
  "view-progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View progress"])},
  "club-delete-confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will delete all dates, session notes and student notes related to this Brick Club"])},
  "this-club-is-private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This club is private"])},
  "this-club-is-public-and-not-accepting-bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select this option if you do not want to accept booking requests"])},
  "this-club-is-public-and-accepting-bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will display your Email and Website"])},
  "please-select-visibility-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select level of visibility"])},
  "do-not-list-on-directory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not list on Brick Club directory"])},
  "list-on-directory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List on Brick Club directory"])},
  "list-on-directory-as-private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List on Brick Club Directory as a private club"])},
  "smartsurvey-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["session-checklist-survey-alert"])},
  "remove-participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove Participant"])},
  "move-club-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move member to another Brick Club"])},
  "date-added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date added"])},
  "add-a-new-discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new Discount"])},
  "verifone-product-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifone Product Code"])},
  "merchant-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant Name"])},
  "percent-off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount Percentage"])},
  "edit-discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit discount"])},
  "license-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License Details"])},
  "issued by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issued by"])},
  "attendee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["attendee"])},
  "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["event"])},
  "subscription-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription Details"])},
  "delegates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delegates"])},
  "invoice-paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["invoice paid"])},
  "payment-gateway-customer-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment gateway customer ID"])},
  "payment-gateway-order-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment gateway Order ID"])},
  "event-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Date"])},
  "club-attendance-help-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is where you can track Brick Club members’ attendance at Brick Club"])},
  "total-no-of-sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total number of sessions"])},
  "total-percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total %"])},
  "group-notes-for-session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group notes for session"])},
  "session-checklist-scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session Checklist scores"])},
  "view-childs-progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Child's Progress"])},
  "secondary-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondary Email"])},
  "secondary-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondary Phone Number"])},
  "event-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["event ID"])},
  "get-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get help"])},
  "your-trainers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Trainers"])},
  "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["question"])},
  "medication-question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Does your child have or take any medication that the Facilitator should know about? "])},
  "additional-question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is there anything else the Facilitator should know about your child to help them have a positive experience at Brick Club?"])},
  "progress-and-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progress and Feedback"])},
  "member-session-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member session feedback"])},
  "club-participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Club Participants"])},
  "goal-tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goal Tracking"])},
  "goal-tracking-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is where you can view and track the goals that your child is working on in Brick Club"])},
  "current-goal-tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Goal Tracking"])},
  "programme-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programme Feedback"])},
  "member-session-feedback-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is where you can review feedback from your child in their Brick Club."])},
  "view-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View feedback"])},
  "view-outcomes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View outcomes"])},
  "club-members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Club members"])},
  "club-members-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is where you can manage your Brick Club members and view their profiles"])},
  "session-checklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session Checklist"])},
  "facilitator-session-checklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilitator session checklist"])},
  "member-attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member Attendance"])},
  "member-progress-survey-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is time to evaluate your Brick Club."])},
  "session-checklist-survey-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Session checklist can help you track how true you are to the Brick-by-Brick® methodology"])},
  "default-brick-club-survey-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How did the Brick Club members find the session today?"])},
  "group-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group notes"])},
  "back-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to"])},
  "member-outcomes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member Outcomes"])},
  "email-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will not change the contact email provided on any live Brick Club Listings in the Brick Club Directory. If you wish to change these please edit the contact email within your Brick Club listing."])},
  "show-facilitator-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show facilitator profile"])},
  "show-trainer-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show trainer profile"])},
  "your-profile-is-currently-visible-trainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your profile is currently visible on the website as a trainer."])},
  "your-profile-is-currently-visible-facilitator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your profile is currently visible on the website as a Facilitator"])},
  "add-a-member-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a Member Note"])},
  "add-member-session-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Member Session Feedback"])},
  "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["goal"])},
  "add-a-goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a Goal"])},
  "date-you-set-this-goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date you set this goal"])},
  "category-of-goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category of goal"])},
  "add-a-member-goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a Participant Goal"])},
  "archive-goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive Goal"])},
  "completion-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completion date"])},
  "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
  "achieved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achieved"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive"])},
  "add-progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add progress"])},
  "goal-progress-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How close are you to reaching this goal:"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["close"])},
  "goal-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goal notes"])},
  "group-goal-tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group goal tracking"])},
  "add-goal-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is where you can update progress towards this goal."])},
  "are-you-sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure?"])},
  "goal-tracking-alert-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please add three goals as a maximum to keep the goals meaningful"])},
  "goal-tracking-helptext-facilitator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We encourage group members to set goals to work towards in Brick Clubs. Here is where you can track your progress."])},
  "goal-tracking-helptext-facilitator-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set your goals at the start, and track progress every session, or at a frequency that's helpful to you."])},
  "no-active-goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no active goals"])},
  "start-score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Score"])},
  "end-goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Score"])},
  "steps-travelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steps travelled"])},
  "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessions"])},
  "no-archived-goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no archived goals"])},
  "member-session-feedback-helptext-facilitator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is where you can add and review session feedback from the Brick Club member. This will be shared with the Brick Club member's family. You can view and review sharing permissions here."])},
  "member-session-feedback-helptext-parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is where you can review what your child has said about the Brick Club in each session. If you have any questions, please contact the Brick Club Facilitator."])},
  "progress-partent-intro-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is where you can add and review feedback about changes you've seen in your child since they started the Brick Club."])},
  "progress-partent-intro-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We recommend providing feedback after your child has attended a few Brick Club sessions. We will give you a reminder 1-2 weeks before your child's Brick Club is due to finish, or a reminder every 3 months if your child is attending Brick Clubs for longer."])},
  "progress-partent-intro-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It would be great to hear your general thoughts and feedback about the Brick-by-Brick Programme."])},
  "you-can-share-your-feedback-here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can share your feedback here"])},
  "progress-parent-helptext-child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is where you can review what your child has said about their experience in Brick Club."])},
  "progress-parent-helptext-parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is where you can review what you have said about the Brick-by-Brick programme."])},
  "progress-parent-helptext-facilitator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is where you can review what the Facilitator and your child has shared about the Brick-by-Brick programme."])},
  "arrow-keys-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please use the arrows to see more dates"])},
  "date-goal-set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date this goal was set"])},
  "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
  "child-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Child notes"])},
  "child-session-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Child session feedback"])},
  "progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progress"])},
  "archived-goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archived Goals"])},
  "child-views": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Child Views"])},
  "graph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graph"])},
  "table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table"])},
  "parent-views": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent Views"])},
  "facilitator-views": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilitator Views"])},
  "renewal-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renewal date"])},
  "auto-renewal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto renewal"])},
  "no-smartsurvey-access-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You currently do not have access to this feature."])},
  "member-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member Notes"])},
  "member-views": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member Views"])},
  "member-progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member Progress"])},
  "club-name-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This name is for your reference only"])},
  "club-address-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will be shown on the Brick Club directory if your club's visibility is set to Public"])},
  "view-brick-club-on-website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View on Brick Club directory"])},
  "club-email-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email will not be visible unless you opt to make your Brick Club public on the Brick Club Directory. You can set your preferences on the next screen"])},
  "host-country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Host country"])},
  "what-does-this-mean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What does this mean?"])},
  "end-goal-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Goal"])},
  "files-download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files (click to download)"])},
  "parent-only-field-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Information that the parent/carer wants you to know, to support the member having a positive experience in Brick Club."])},
  "first-delivery-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Delivery Date"])},
  "event-tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply to events with tag (optional)"])},
  "usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usage"])},
  "brick-club-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brick Club Name"])},
  "publish-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["publish date"])},
  "onboarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onboarding"])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
  "professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional"])},
  "parent-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select this option if you are a parent who would like access to parent resources"])},
  "professional-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select this if you are a professional that would like to access professional resources and potentially enrol on professional courses. "])},
  "parent-no-smartsurvey-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This feature is not yet available for your child’s Brick Club. You will be notified by email if this feature becomes available"])},
  "facilitator-no-smartsurvey-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This beta feature is not available to your subscription type at present. We will email you to notify you when it becomes available."])},
  "trainer-no-smartsurvey-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This beta feature is not available to your subscription type at present. We will email you to notify you when it becomes available."])},
  "facilitator-evaluation-nudge-banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How did you find the Brick Club experience? Please complete 'End of Brick Club' feedback questionaire"])},
  "evaluation-nudge-banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How did you find the Brick Club experience? Please complete the feedback questionaire"])},
  "parent-evaluation-nudge-banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How did your child find the Brick Club experience? Please complete the feedback questionaire"])},
  "parent-evaluation-nudge-banner-outcomes-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is time to share your views on any changes you have noticed since your child started brick club"])},
  "evaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluation"])},
  "complete-survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["complete survey"])},
  "complete-background-survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete Background Survey"])},
  "view-session-attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Session Attendance and Notes"])},
  "add-presenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Presenter"])},
  "no-presenters-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No presenters available"])},
  "brick-club-schedule-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All dates for your brick club need to be added, however, for initial set up, a minimum of 1 date must be added and further dates can be added post set up by editing a brick club"])},
  "no-places-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no places available"])},
  "place-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["place available"])},
  "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["roles"])},
  "affiliate-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiliate ID"])},
  "funds-recieved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funds received at"])},
  "view-all-clubs-length-brick-clubs": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["View all ", _interpolate(_list(0)), " Brick Clubs"])},
  "view-all-facilitators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View all Facilitators"])},
  "facilitators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilitators"])},
  "please-select-a-brick-club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a brick club"])},
  "no-brick-clubs-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No brick clubs available"])},
  "attendance-change-warning-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note, changing the date here will change the scheduled date on your brick club also. Please only change if the brick club session date moved"])},
  "record-member-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record member session feedback and goals"])},
  "select-country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select country"])},
  "save-selected-country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save selected country"])},
  "tax-free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax free"])},
  "total-meta-pagination-total_entries-items": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Total ", _interpolate(_list(0)), " items"])},
  "manage-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Profile"])},
  "public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["public"])},
  "private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["private"])},
  "viewable-on-website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(viewable on website)"])},
  "not-viewable-on-website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(not viewable on website)"])},
  "invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["invoice"])},
  "not-paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["not paid"])},
  "tax-registration-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Registration Number"])},
  "purchase-order-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase Order Number"])},
  "not-received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["not received"])},
  "payment-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Amount"])},
  "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tax"])},
  "redeemed-license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redeemed License"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["search"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["active"])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pending"])},
  "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["expired"])},
  "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["none"])},
  "manual-subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual Subscription"])},
  "subscribe-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe User"])},
  "view-all-orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View all Orders"])},
  "this-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Week"])},
  "this-month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Month"])},
  "last-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Week"])},
  "view-all-courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View all courses"])},
  "add-a-parent-carer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a parent/carer"])},
  "upload-resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Resource"])},
  "add-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Event"])},
  "there-was-an-error-fetching-the-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error fetching the event"])},
  "there-was-an-error-updating-this-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error updating this event"])},
  "event-updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event updated"])},
  "there-was-an-error-updating-event-visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error updating event visibility"])},
  "event-visibility-updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event visibility updated"])},
  "show-on-google-maps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show on Google Maps"])},
  "event-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Details"])},
  "this-will-cancel-the-event-and-notify-all-registered-attendees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will cancel the event and notify all registered attendees."])},
  "unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlimited"])},
  "confirm-event-cancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm event cancellation"])},
  "error-updating-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error updating Event"])},
  "i-e-paypal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i.e PayPal"])},
  "includes-tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Includes Tax?"])},
  "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
  "please-add-a-direct-link-to-help-the-user-find-the-venue-e-g": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please add a direct link to help the user find the venue e.g."])},
  "google-maps-optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Maps (Optional)"])},
  "please-select-a-country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a Country"])},
  "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])},
  "address-line-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address Line 2"])},
  "address-line-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address Line 1"])},
  "selectedtemplate-type-e-learning-e-learning-online-meeting-link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " link"])},
  "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional"])},
  "event-summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Summary"])},
  "you-are-editing-a-event-status-event-any-changes-will-effect-this-event": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["You are editing a ", _interpolate(_list(0)), " event. Any changes will effect this event."])},
  "error-creating-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error creating Event"])},
  "event-created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event created"])},
  "please-select-a-country-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a country"])},
  "submit-training-event-for-review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit Training Event for Review"])},
  "save-as-draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save as Draft"])},
  "selectedtemplate-delivery-e-learning-e-learning-online-meeting-link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " link"])},
  "all-times-are-in-short_timezone": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["All times are in ", _interpolate(_list(0))])},
  "for-more-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for more information."])},
  "play-included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play Included"])},
  "there-are-currently-no-templates-available-to-you-please-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are currently no templates available to you, please contact"])},
  "use-this-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use this Template"])},
  "to-get-started-please-select-a-course-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To get started, please select a course template"])},
  "introductory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introductory"])},
  "continuing-professional-development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuing Professional Development"])},
  "drop-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop in"])},
  "Training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training"])},
  "add-course-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Course Template"])},
  "view-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Template"])},
  "key-programme-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key Programme Details"])},
  "moodle-course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moodle course"])},
  "registration-rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration Rules and Method"])},
  "please-add-atleast-1-rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please add at least 1 registration rule."])},
  "edit-registration-rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Registration Rules"])},
  "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pricing"])},
  "please-add-atleast-one-country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please add at least 1 country and price"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit"])},
  "add-a-country-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new country price"])},
  "countries-and-pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Countries and pricing"])},
  "subscription-level-on-completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription Level on Completion"])},
  "merchant-product-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant Product Code"])},
  "merchant-product-code-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you enter a Verifone Product Code the prices in the Countries and pricing table will not be applied. Instead the prices from 2Checkout will be used."])},
  "merchant-product-code-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NOTE: Please still complete the countries below to ensure the product will appear on the website."])},
  "save-course-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Course Template"])},
  "select-course-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a course description"])},
  "view-course-in-cms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View course descriptions in CMS"])},
  "storyblok-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storyblok Description"])},
  "template-created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template created"])},
  "error-creating-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error creating Template"])},
  "template-updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template updated"])},
  "error-updating-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error updating Template"])},
  "new-course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Course"])},
  "country-and-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country and price"])},
  "excluding-vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excluding VAT"])},
  "select-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select all"])},
  "user-must-be": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User must be"])},
  "add-registration-rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Registration Rules"])},
  "latest-course-templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest course templates"])},
  "see-all-course-templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See all course templates"])},
  "upcoming-training-events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upcoming training events"])},
  "see-all-training-events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See all training events"])},
  "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online"])},
  "no-date-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Date Available"])},
  "event-invoice-marked-as-issued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event invoice marked as issued"])},
  "event-invoice-marked-as-paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event invoice marked as paid"])},
  "discount-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount Details"])},
  "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
  "delete-discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Discount"])},
  "error-deleting-discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error deleting Discount"])},
  "discount-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Discount deleted"])},
  "add-a-discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a discount"])},
  "discount-used": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0))])},
  "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tags"])},
  "enter-new-tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter new tags separated by space"])},
  "tag-name-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag name cannot be empty"])},
  "tag-created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag created"])},
  "error-creating-tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error creating tag"])},
  "current-subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Subscription"])},
  "subscription-updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription updated"])},
  "error-updating-subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error updating Subscription"])},
  "user-subscribed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User subscribed"])},
  "error-subscribing-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error subscribing User"])},
  "include-tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inc. VAT"])},
  "exclude-tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excl. VAT"])},
  "course-prerequisites-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course prerequisites"])},
  "to-attend-the-booking-event-name-course-please-accept-our-mandatory-course-prerequisites": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["To attend the ", _interpolate(_list(0)), " course, please accept our mandatory course prerequisites."])},
  "view-club-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Club Details"])},
  "this-user-has-no-brick-clubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This user is yet to run any Brick Clubs"])},
  "course-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Details"])},
  "initiate-course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initiate Course"])},
  "facilitator-course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilitator Course"])},
  "completed-event-date": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Completed ", _interpolate(_list(0))])},
  "in-progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In progress"])},
  "view-certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Certificate"])},
  "this-user-has-no-events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This user is yet to enrol in any Training Events"])},
  "you-have-licenses-available": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["You have ", _interpolate(_list(0)), " redeemable places and ", _interpolate(_list(1)), " pending for this type of event"])},
  "add-user-to-course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add User to Course"])},
  "organisation-has-licenses-available": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["This organisation has ", _interpolate(_list(0)), " redeemable places and ", _interpolate(_list(1)), " pending places for this type of event"])},
  "active-courses-by-play-included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Courses by Play Included"])},
  "your-facilitators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Facilitators"])},
  "view-courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View courses"])},
  "courses-cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Build your organisational skills by booking your team for a training event near you."])},
  "your-brick-clubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Brick Clubs"])},
  "view-all-clubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View all brick clubs"])},
  "continue-events-cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue your Brick-by-Brick® journey by booking onto the Brick-by-Brick® Programme: Facilitator course today."])},
  "update-subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update subscription"])},
  "subscription-expires": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Subscription expires - ", _interpolate(_list(0))])},
  "subscription-expired": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Subscription expired - ", _interpolate(_list(0))])},
  "current-membership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current membership"])},
  "view-all-trainers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View all Trainers"])},
  "view-all-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View all users"])},
  "copy-event-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy Event Link"])},
  "trainer-pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trainer Pricing"])},
  "club-note-member-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is where you can make notes about a Brick Club session. These notes will not be visible to anyone other than you. You can write notes about individual members                 and opt into share with parents/carers on the member outcomes."])},
  "start-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date"])},
  "end-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date"])},
  "export-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export Data"])},
  "export-club-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export Brick Club Data"])},
  "export-goals-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export Goals data"])},
  "create-manual-booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Manual Booking"])},
  "notes-only-visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes are only visible to yourself and optionally, the member's parent or carer"])},
  "view-resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View resource"])},
  "view-resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View resources"])},
  "no-resources": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["There are currently no ", _interpolate(_list(0)), " resources"])},
  "role-resources": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " Resources"])},
  "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move"])},
  "manage-license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage license"])},
  "credit-debit-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit/Debit Card"])},
  "single-booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single Booking"])},
  "group-booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group Booking"])},
  "answer-choices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer Choices"])},
  "responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responses"])},
  "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
  "subscription-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription Status"])},
  "no-organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Organisation"])},
  "manual-add-user-to-course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual add user to course"])},
  "user-is-ineligible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User is ineligible"])},
  "discount-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount amount"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["total"])},
  "select-marketing-preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Marketing Preferences"])},
  "marketing-preferences-copy-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to your Play Included portal! We’re delighted you could join us. Before you look around, please take a moment to share your communication preferences with us. We would love to keep you informed about exciting news, tips and resources. You will automatically receive emails about your membership or purchases, but to receive additional emails please provide your consent below."])},
  "i-would-like-to-receive-emails-from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I would like to receive emails from Play Included about:"])},
  "marketing-preferences-copy-2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["We take your privacy seriously and your data will be treated sensitively in accordance with the ", _interpolate(_list(0)), ". You can change your preferences at any time by visiting ‘My Profile’ in the portal."])},
  "privacy-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
  "requires-a-subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requires a subscription?"])},
  "subscription-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription amount"])},
  "tax-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax amount"])},
  "total-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Amount"])},
  "renewal-only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renewal Only"])},
  "renewals-only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renewals Only"])},
  "affiliate_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiliate ID"])},
  "template_field_disabled": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Some fields have been disabled as this course has ", _interpolate(_list(0)), " events in the system. If you need to change any of the disabled fields, you will need to create a new template and event"])},
  "no-change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Change"])},
  "payment-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment status"])},
  "discount-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount ID"])},
  "discount-percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount percentage"])},
  "discounted-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discounted amount"])},
  "pretax-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-tax Amount"])},
  "please-enter-a-payment-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a payment link"])},
  "please-enter-a-payment-gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a payment gateway"])},
  "licenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licenses"])},
  "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subscription"])},
  "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subscription"])},
  "One-off fee Product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One-off fee Product"])},
  "Bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bookings"])},
  "add-new-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new user"])},
  "license-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License"])},
  "added-by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added by"])},
  "redeem-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redeem Place"])},
  "first-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Date"])},
  "group-booking-financial-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group booking financial information"])},
  "places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Places"])},
  "net-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net price"])},
  "delegate-within-own-organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delegate within my organisation"])},
  "bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bookings"])},
  "transaction-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction ID"])},
  "order-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Date"])},
  "refunded?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refunded?"])},
  "refunded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refunded"])},
  "back-to-website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to our website"])},
  "all-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All time"])},
  "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sales"])},
  "abandoned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abandoned"])},
  "select-your-language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select your language"])},
  "step-of-four": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["step ", _interpolate(_list(0)), " of 4"])},
  "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])},
  "parent-onboard-permissions-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play Included’s portal is the place where we manage and monitor Brick Clubs. At Play Included, we aim to deliver high quality Brick Club experience to members, and we believe that providing tools which allow you to manage and monitor Brick Clubs is essential in reaching that aim. You can read more about how we manage data related to Brick Club members and Brick Clubs here"])},
  "parent-onboarding-attendance-permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree to allow the Brick Club Facilitator to use the Play Included portal to record my child’s attendance, and add session notes about my child. This data will be           visible to the Brick Club Facilitator, you as the members’ family, any Brick Club administrators and Play Included."])},
  "parent-onboarding-survey-permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree to allow the Facilitator to record my child’s progress in Smart Survey and in the Play Included portal. This information will be shared with you (and any additional parent/carers, where relevant) in the Play Included portal. The data will also be visible to the Brick Club Admin and Play Included."])},
  "parent-onboarding-no-permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I do not give my consent for the Facilitator to record attendance or progress in Brick Clubs. I understand that my child’s attendance or progress in Brick Clubs will not be recorded."])},
  "parent-onboarding-aware-of-participation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where appropriate, my child is aware that they are participating in the Brick-by-Brick® programme and have been added to Play Included’s portal. If your child is not happy to participate please contact the Brick Club facilitator. You and your child have the opportunity to choose what data you share with Brick Club Facilitators, administrators and Play Included and you can manage these permissions in the 'My children' section of your portal under 'Manage profile'"])},
  "parent-onboarding-aware-check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" here."])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["next"])},
  "parent-onboarding-started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lets get started."])},
  "parent-onboarding-started-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To ensure that we deliver the best Brick Club experience, we would like to confirm some information from you about your child."])},
  "parent-onboarding-started-text-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Including this information means that your child’s initials will be used throughout the portal, making it more personal and individual to you and your child. You can change the information in your child's profile at any time in the portal."])},
  "parent-onboarding-started-text-3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["You can read about how Play Included manage personal data ", _interpolate(_list(0))])},
  "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["here"])},
  "child-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Child Details"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["back"])},
  "parent-carer-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent or Carer details"])},
  "parent-carer-details-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next, we will collect your details. These will only be used to contact you about Brick Club and any update to your child’s data within our system. You will also be emailed       updates. You can edit your communication settings under 'My Profile'."])},
  "parent-carer-details-text-3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You can update, edit or modify your contact information under ", _interpolate(_named("strong")), " at any time."])},
  "parent-carer-details-text-2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Information on how Play Included store and manage contact information is available ", _interpolate(_list(0))])},
  "member-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member profile"])},
  "member-profile-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We want Brick Clubs to be fun, safe spaces for members. For our Facilitators, having a little extra information about members before they come to Brick Club can help them       create that welcoming environment and ensure the safety and wellbeing of members."])},
  "member-profile-text-2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["This information will only be shared with the Brick Club Facilitator and the Facilitator Organisation (if relevant) and should only include information that is necessary to ensure smooth and safe operation of the Brick Club. This data will not be available to Play Included, you can read about how Play Include manage data ", _interpolate(_list(0))])},
  "medication-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please do not add any identifying notes here (e.g. address, childs full name or date of birth)"])},
  "make-primary-parent-carer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make Primary Carer"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
  "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change your password"])},
  "change-password-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To edit your password you will be sent to our Single Sign On (SSO) system."])},
  "all-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Roles"])},
  "resources-pagination": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Total ", _interpolate(_list(0)), " items"])},
  "create-brick-club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Brick Club"])},
  "show-all-events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show events only in my organisation"])},
  "show-my-events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show events I've booked delegates on to"])},
  "view-my-delegates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View my delegates"])},
  "unauthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized Access"])},
  "annual-subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annual Subscriptions"])},
  "view-subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Subscription"])},
  "background-notes-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This information has been added by the child’s parent/carer and is not editable by Facilitators. "])},
  "member-medication-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Does the member take any medication?"])},
  "parent-carer-consent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent/Carer Consent"])},
  "parent-carer-survey-permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent/Carer has consented to attendance and session notes being recorded by the Facilitator in the Play Included portal"])},
  "parent-carer-progress-permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent/Carer has consented to progress being recorded by the Facilitator in the Play Included portal"])},
  "parent-carer-attendance-notes-permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent/Carer has consented to attendance and session notes being recorded by the Facilitator in the Play Included portal"])},
  "parent-carer-no-attendance-notes-permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent/Carer has not consented to attendance or progress being recorded in the Play Included portal"])},
  "member-background-info-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This information has been added by the child’s parent/carer and is not editable by Facilitators."])},
  "member-medication-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Does the member take any medication?"])},
  "member-additional-notes-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information that the parent/carer wants you to know, to support the member having a positive experience in Brick Club."])},
  "parent-carer-subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent/Carer Consent"])},
  "parent-attendance-session-notes-consent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Parent/Carer ", _interpolate(_list(0)), " consented to attendance and session notes being recorded by the Facilitator in the Play Included portal"])},
  "parent-survey-consent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Parent/Carer ", _interpolate(_list(0)), " consented to progress being recorded by the Facilitator in the Play Included portal"])},
  "parent-no-consent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent/Carer has not consented to attendance or progress being recorded in the Play Included portal"])},
  "your-bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your bookings"])},
  "user_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Group"])},
  "training_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training Event"])},
  "training_events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training Events"])},
  "user_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Groups"])},
  "add_a_new_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new event"])},
  "brick_clubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brick Clubs"])},
  "all-types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All types"])},
  "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
  "facilitation-aid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilitation Aid"])},
  "club-promotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Club Promotion"])},
  "supporting-asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supporting Asset"])},
  "training-resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training Resources"])},
  "all-tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Tags"])},
  "all-languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Languages"])},
  "cpd-for-certified-facilitators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CPD Courses for Facilitators"])},
  "add_to_moodle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to Moodle"])},
  "create-account-in-moodle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create account in Moodle"])},
  "find-out-how": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find out how"])},
  "score-key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score key: 0 (no progress) 1 – 2 – 3 – 4 – 5 (half way there) – 6 – 7 – 8 – 9 – 10 (goal achieved)"])},
  "score-key-no-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 (no progress) 1 – 2 – 3 – 4 – 5 (half way there) – 6 – 7 – 8 – 9 – 10 (goal achieved)"])},
  "score-key-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A score of 0 represents no progress towards the goal, a score of 5 represents half-way, and a score of 10 represents that the goal is completely achieved, however, any score 8 or above can be considered achieved. A change of 3 steps or more represents meaningful progress."])},
  "download-report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Report"])},
  "all-categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Categories"])},
  "parent-goal-tracking-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is where you can track your child's progress towards their goals in the Brick Club. If you have any questions, please contact the Brick Club Facilitator"])},
  "learn-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more here"])},
  "goal-tracking-max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can only have three goals at a time. Please archive a goal to add a new one."])},
  "add-goal-modal-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It’s really important to set goals carefully, according to guidance."])},
  "session-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session Date"])},
  "archive-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for archive"])},
  "archive-achieved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has the goal been achieved (Anything 8 and above is an achieved goal)?"])},
  "archived-goals-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is where you can review previous and archived goals that you tracked in Brick Club."])},
  "archived-goals-helptext-parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is where you can view previous goals your child has worked on at the Brick Club. If you have any questions, please contact the Brick Club Facilitator."])},
  "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
  "meaningful-progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meaningful Progress"])},
  "brick-club-register-drop-in-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After every brick club session remember to add; attendance, member session feedback, Facilitator session checklist and add progress towards goals."])},
  "brick-club-register-drop-in-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need help with your Brick Club? Register to attend a free drop-in session with a Play Included trainer "])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
  "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percent"])},
  "total-clubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Clubs in total"])},
  "total-club-members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total number of club members"])},
  "total-members-meaningful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of member with meaningful progress in at least 1 goal"])},
  "total-members-achieved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of member with at least 1 goal achieved (scored 8, 9, 10)"])},
  "total-goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Goals"])},
  "total-goals-meaningful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Goals with Meaningful progress"])},
  "total-goals-achieved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Goals Achieved"])},
  "play-at-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play at Home"])},
  "play-at-home-banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Play at Home activities could help facilitate more conversations about Brick Club"])},
  "max-attendees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max attendees"])},
  "attendees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendees"])},
  "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Removed"])},
  "booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking"])},
  "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])},
  "order-on-website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order on Website"])},
  "access-training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access Training"])},
  "training-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training status"])},
  "certified-facilitator-course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certified Facilitator Course"])},
  "advanced-facilitator-course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced Facilitator Course"])},
  "product-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Code"])},
  "add-a-new-organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new Organisation"])},
  "add-new-organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new Organisation"])},
  "organisation-ran-by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ran by"])},
  "course-blocked-pending-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This course must be paid for and the prereqs completed"])},
  "upcoming-courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upcoming Courses"])},
  "not-started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not started"])},
  "no-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is currently no feedback logged for this member."])},
  "average-course-satisfaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average course satisfaction"])},
  "average-brick-club-session-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average Brick Club session feedback"])},
  "out-of-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(out of 10)"])},
  "view-satisfaction-breakdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View satisfaction breakdown"])},
  "facilitator-banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Its time to share your training course feedback"])},
  "nag-banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It doesn't look like you have added any progress to any of your member goals. You can add progress to member goals here. Please note that you will need to select a member first and then click on the ‘Goal tracking’. "])},
  "product_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Code"])},
  "discounted_product_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discounted Product Code"])},
  "discount_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount Code"])},
  "subscriptions_purchased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscriptions Purchased"])}
}